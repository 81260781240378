@import 'common-flagship_tasting_room';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}

.b-categories_navigation-link_1 {
	color: $color-shade_5;
	font-size: 20px;
	font-weight: 300;
	text-transform: none;
	letter-spacing: 0.5px !important;
}

.b-header-links_item.m-stores {
	font-size: 18px;
	color: $color-shade_6;
	
	&:before {
		background: var(--icon-color,$color-shade_1) !important;
	}
}

.b-header-utility_item.m-search {
	font-size: 18px;
	color: $color-shade_6;

	@include media(md-down) {
		font-size: 0;
	}
	
	&:before {
		background: var(--icon-color,$color-shade_1) !important;
	}
}

.t-paragraph_2 {
	font-size: 18px;
}

.b-footer{
	border-top:2px solid $color-footer-border;

	&_navigation-title {
		font-size: 18px;
		font-family: $font-alt1;
		color: $color-shade_6;
		text-transform: capitalize !important;
	}
	&-newsletter {
		color: $color-shade_3;
	}
	&_social-title {
		text-transform: none;
		color: $color-shade_6;
		font-weight: 500;
		font-size: 18px;
		font-family: $font-alt1;
		@include media(sm) {
			text-align: center;
		}
	}
	&_navigation-link {
		color: $color-shade_7;
		font-size: 18px;
	}
	&-copyright {
		opacity: 1;
		font-size: 14px !important;
		line-height: 18px;
		color: $color-shade_6;
	}
	&_legal-links {
		color: $color-shade_7;
	}
	&_legal-link {
		font-size: 14px;
		line-height: 18px;
        color: $color-shade_7;
    }

	&-customer_service , &-need_help{
		visibility: hidden;
	}

	&_social {
		@include media(md-up) {
			padding-top: 64px;
		}
		&-links {
			color: $color-shade_1;
			justify-content: flex-start;
			@include media(sm){
				justify-content: center;
			}
		   & a {
				margin-right: 16px;
			}
		}
		.content-asset {
			@include media(sm) {
				border-top: none;
			}
		}
	}
}

.b-tab_list-tab.m-active {
    border-bottom: 3px solid $color-shade_1;
}

.b-categories_navigation { 
	&-item_1 {
		padding: 0 23px !important;
	}
    &-item_1:after {
        border-bottom: 3px solid $color-shade_11;        
    }
}

.b-categories_navigation{
	&-list_3 {
		background-color: $color-primary-background;
	}

	&-group_1 {
		background-color: $color-primary-background;
		color: $color-shade_6;
	}
}

.b-header {
	&-hamburger_cta, &-close_hamburger {
		color: $color-shade_1;
	}
}

// back to top icon
.b-back_to_top {
	@include media(sm) {
		&-icon {
			margin-bottom: 0 !important;
		}
		&-icon_circle {
			fill: transparent;
			color: $color-shade_1 !important;
		}
		&-icon_arrow {
			fill: $color-shade_1;
		}
		&-copy {
			font-size: 18px;
			color: $color-shade_6;
			font-weight: 300;
			text-transform: capitalize;
			margin-top: 3%;
		}
	}
}

.b-product_tile-image {
	background: transparent;
}

.logo-desktop {
	position: initial;
	@include media(sm){
		display: none;
	}
}
.logo-mobile {
	position: initial;
	@include media(md-up) {
		display: none;
	}
}

.b-logo-wrapper {
	padding-bottom: 0;
}

.b-age_gate-header .logo-desktop {
	width: 300px;
	@include media(sm){
		display: block;
	}
}

.b-breadcrumbs {
	&-link {
		font-weight: 300;
		color: var(--color-link, $color-shade_7) !important;
	}
    &-item {
        align-items: baseline;
        &:not(.m-current) {
            @include media(md-up) {
                @include icon('arrow-right', 8px, 8px, after) {
                    background: $color-shade_1;
                    margin: 2px 15px 0;
                }
            }
        }
    }
	&-list {
		font-size: 16px !important;
		letter-spacing: 0.5px;
		line-height: 24px;
	}
}

.b-breadcrumbs-link.m-current {
	color: var(--color-link, $color-shade_6) !important;
}

.b-header-navigation_link_inner {
	font-size: 18px;
	color: $color-shade_6;
	
	&:before {
		background: var(--icon-color,$color-shade_1) !important;
	}
}

.b-customer_service_info {
	&-icon {
		color: $color-shade_1;
	}
	&-link { 
		color:$color-primary !important;
	}
}


 .f-input_select-field {
	color: $color-primary !important;
	padding: 12px 25px 8px 15px;
	@include media (sm) {
		height: 56px;
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.b-store_locator-link:after{
	background: var(--color-link, $color-shade_1) !important;

}

.b-header-background {
	border-bottom: 1px solid $color-shade_2;
}

.b-categories_navigation-group_1 {
	background-color: $color-primary-background;
}

.b-variations_item-label {
    color: $color-shade_5 !important;
}

.b-load_progress-description {
	font-weight: 300 !important;
	font-size: 18px !important;
	text-transform: none !important;
}

.b-product_badge {

	&-image {
		background: none !important;
		border: none;
	}
	
	&.m-rating_points {
		background-image: url('./svg-icons/badge-shape.svg');
		border-radius: 50%;
	}
    &-text {
		font-size: 14px !important;
        color: $color-shade_5;
		font-family: $font-main;
    }
    &-points {
        color: $color-shade_5;
        font-size: 20px !important;
		font-family: $font-main;
    }
}

.b-zoom_info-icon svg {
	stroke: $color-shade_1;
}

.b-variation_swatch-value { 
	border-radius: 0 !important;
}

.b-expandable.m-product_description.m-collapsed {
	.b-product_description-text {
		@include lines-clamp(5);

		@include media (sm) {
			@include lines-clamp(7);
		}
	}
}

.l-page #wtb-overlay #mobileNavWrapper #mapNav[data-map-nav-clicked=true] {
	color: $color-shade_5;
}

.f-input_select:before {
	color: $color-shade_1;
}

.b-form-btn_alt {
	background-color: $color-shade_11;
	color: $color-primary-background;
	border-color: $color-shade_11;
	font-family: $font-alt3;
	&:hover {
		color: $color-shade_7;
		background-color: $color-primary-background !important;
	}
}

.f-input_checkbox-field { 
	@include media(sm) {
		margin-top:5px;
	}
    margin: 10px 10px 0 0;
	background: $color-shade_1;
}

.b-contact_us-form_link { 
	color: $color-shade_7 !important;
}

.f-input_textarea-label {
    color: $color-shade_5
}

.f-input_radio-field, .l-page #wtb-overlay #closeBtn:after,.b-filter_selected-remove:before { 
	background: $color-shade_1 !important;
}

.b-search_input-clear.m-visible { 
	color: $color-shade_7;
    text-decoration: underline;
}

.b-search_input-submit:before , .b-button_close:before, .b-refinements_accordion-button:after{
	background: $color-shade_1 !important;
}

.b-age_gate {
	background-color: $color-primary-background;
}

.b-age_gate-main {
	background-image: url(../images/age-gate-background.jpg);
    background-size: 597px 245px;
    background-position: right 45px bottom -50px;
    background-repeat: no-repeat;
    padding-bottom: 170px;

	@include media(sm) {
		background-position: center bottom -50px;
	}

	.b-age_gate-title {
		font: $configuration-t_heading_2-font;
		color: $color-shade_1;
	}
}

.age-gate-enter-form .cta_btn--age-gate {
	background: $color-shade_11;
	color: $color-primary-background;
}

a.g-button_main, input.b-store_locator-submit {
	background-color: $color-shade_11;
	border-color: $color-shade_11;
	color: $color-primary-background;
	font-family: $font-alt3;
}

h1 {
	font-family: $font-alt;
	color: $color-shade_11;
}

h2 {
	font-family: $font-alt1;
	color: $color-shade_1;
}