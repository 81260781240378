// Cookies acceptance / Consent tracking modeless dialog
.b-age_gate {
	$_age_gate-background_image: $configuration-age_gate-background_image;
	$_age_gate__mobile-background_image: $configuration-age_gate__mobile-background_image;

	background: none;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: z(notification-panel);

	@include media(sm) {
		background: none;
	}

	&-inner {
		color: $color-age_gate-text;
		column-gap: var(--grid-gutter);
		display: grid;
		grid-template:
			[start header-s]
			auto //1
			[header-e content-s]
			auto //2
			[content-e footer-s]
			1fr // 3
			[end footer-e]
			/
			[start footer-s]
			1fr //1
			[]
			1fr //2
			[]
			1fr //3
			[content-s]
			1fr //4
			[]
			1fr //5
			[header-s]
			1fr //6
			[]
			1fr //7
			[header-e]
			1fr //8
			[]
			1fr //9
			[content-e]
			1fr //10
			[]
			1fr //11
			[]
			1fr //12
			[end footer-e];
		height: 100%;
		overflow-y: auto;
		padding: 0 var(--grid-margin);
		text-align: center;

		@include media(md) {
			grid-template:
				[start header-s]
				auto //1
				[header-e content-s]
				auto //2
				[content-e footer-s]
				1fr // 3
				[end footer-e]
				/
				[start footer-s]
				1fr //1
				[]
				1fr //2
				[content-s]
				1fr //3
				[]
				1fr //4
				[]
				1fr //5
				[header-s]
				1fr //6
				[]
				1fr //7
				[header-e]
				1fr //8
				[]
				1fr //9
				[]
				1fr //10
				[content-e]
				1fr //11
				[]
				1fr //12
				[end footer-e];
		}

		@include media(sm) {
			grid-template:
				[start header-s]
				auto //1
				[header-e content-s]
				auto //2
				[content-e footer-s]
				1fr // 3
				[end footer-e]
				/
				[start header-s content-s footer-s]
				1fr //1
				[]
				1fr //2
				[]
				1fr //3
				[]
				1fr //4
				[end header-e content-e footer-e];
		}
	}

	&-header {
		grid-area: header-s / header-s / header-e / header-e;
		justify-self: center;
		margin: rh(10 0 12);

		@include media(sm) {
			margin: rh(10 0 12);
		}
	}

	&-main {
		grid-area: content-s / content-s / content-e / content-e;
	}

	&-footer {
		align-self: end;
		grid-area: footer-s / footer-s / footer-e / footer-e;
		margin-bottom: rh(4);
	}

	&-title {
		@include t-heading_1;

		margin-bottom: rh(8);

		@include media(sm) {
			margin-bottom: rh(10);
		}
	}

	&-subtitle {
		@include t-paragraph_2;

		margin: 0 auto rh(4);
	}

	&-description {
		@include t-paragraph_3;

		margin-bottom: rh(4);
	}

	&-button {
		@include g-button($configuration-age_gate-button_type, $configuration-age_gate-button_modification);

		display: block;
		margin: 0 auto 20px;
		width: 335px;
	}

	&-button_link {
		@include t-link_1;

		font-size: 14px;
		margin-bottom: rh(10);
	}

	&-link {
		@include t-link_2;

		font-size: 14px;
		font-weight: 400;
	}

	.b-footer-bottom_info {
		border: 0;
	}

	.b-footer_legal-links {
		@include media(md) {
			justify-content: center;
		}
	}
}

.age-gate-overlay {
	height: 100%;
	width: 100%;
}

.age-gate-faded {
	position: fixed;
	height: 65%;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(48, 48, 48, 0.5);
	z-index: 10;

	@include media(sm) {
		height: 55%;
	}
  }

  .age-gate-popover {
	padding: 40px 56px;
    display: grid;
	grid-column-gap: 120px;
	grid-template-columns: 2fr 1fr;
	grid-template-areas: "cookies_asset cookies_buttons";
	position: fixed;
	background: #ffffff;
    height: 35%;
	overflow: auto;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

	@include media(md) {
		grid-column-gap: 20px;
	}

	@include media(sm) {
		height: 45%;
		padding: 40px 19px;
		grid-template-columns: 1fr;
		grid-template-areas: "cookies_asset"
		 					 "cookies_buttons";
	}
}

.cookies-and-privacy-policy-asset {
	grid-area: cookies_asset;
	display: flex;
    align-items: end;
	@include media(sm) {
		text-align: center;
		align-items: center;
	}
}

.accept-cookies-buttons {
	grid-area: cookies_buttons;
	display: flex;
    align-items: end;
	@include media(sm) {
		display: block;
	}
	button {
		height: 48px;
		width: 208px;
		margin: 0px 5px;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		@include media(md) {
			width: 100%;
			font-size: 12px;
		}
		@include media(sm) {
			width: 100%;
		}
	}
}

.age-gate-overlay {
	height: 100%;
	width: 100%;
}

.age-gate-faded {
	position: fixed;
	height: auto;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(48, 48, 48, 0.5);
	z-index: 10;
  }

  .age-gate-popover {
	padding: 40px 56px;
    display: grid;
	grid-column-gap: 120px;
	grid-template-columns: 2fr 1fr;
	grid-template-areas: "cookies_asset cookies_buttons";
	align-items: end;
	position: fixed;
	background: #ffffff;
    height: auto;
	overflow: auto;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

	@include media(md-down) {
		padding: 40px 19px;
		grid-template-columns: 1fr;
		grid-template-areas: "cookies_asset"
		 					 "cookies_buttons";
	}
}

.cookies-and-privacy-policy-asset {
	grid-area: cookies_asset;
    align-items: end;
	@include media(md-down) {
		text-align: center;
		align-items: center;
		margin-bottom: 12px;
	}
}

.accept-cookies-buttons {
	grid-area: cookies_buttons;
    align-items: end;
	display: grid;
    grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
	@include media(md-down) {
		display: block;
	}
	button {
		height: 48px;
		width: 100%;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		@include media(md-down) {
			margin: 12px 0;
		}
	}
}